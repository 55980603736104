import { getFormDefinition, createFormDataFromItem, createItemFromFormData } from './form';
import { entityToStore } from '@/utils/store';
import baseCrudMixin from '@/mixins/crud';

export default {
  computed: {
    definition() {
      return this.createDefinition();
    },
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  mixins: [baseCrudMixin],
  data: () => ({
    entity: 'DossierAction',
    dateStampToday: new Date().toISOString().substring(0, 10),
    returnTo: 'Dossiers'
  }),
  created() {
    const dossierId = this.dossier ? this.dossier.dossierID : this.$route.params.dossierID;
    this.$store.dispatch('actionType/getItems');
    this.$store.dispatch('actionStatus/getItems');
    this.$store.dispatch('role/getItems');
    if (dossierId > 0) {
      this.$store.dispatch('loan/loansByDossierId', dossierId);
    }
  },
  methods: {
    getDependencies(data) {
      return [{ entity: 'actionType' }, { entity: 'actionStatus' }, { entity: 'role' }];
    },
    createDefinition() {
      return getFormDefinition(this);
    },
    createFormDataFromItem,
    createItemFromFormData
  }
};
